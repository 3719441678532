import React, { useState, useEffect, useRef } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "./calculadora.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CalculadoraFaqs from "./CalculadoraFaqs";
// Necesario para registrar todos los componentes de Chart.js
Chart.register(...registerables);

const Calculadora = () => {
  const [capitalInicial, setCapitalInicial] = useState(1000);
  const [aportacionMensual, setAportacionMensual] = useState(100);
  const [anos, setAnos] = useState(10);
  const [tasaInteres, setTasaInteres] = useState(8);
  const [resultado, setResultado] = useState(null);
  const [ganado, setGanado] = useState(null);
  const [dataChart, setDataChart] = useState([]);
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const chartContainerRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const getChartColors = () => {
    return {
      text: isDarkMode ? "#ffffff" : "#000000",
      grid: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
      tooltip: {
        background: isDarkMode
          ? "rgba(0, 0, 0, 0.8)"
          : "rgba(255, 255, 255, 0.8)",
        text: isDarkMode ? "#ffffff" : "#000000",
        border: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
      },
    };
  };

  useEffect(() => {
    const savedMode = localStorage.getItem("darkMode");
    if (savedMode !== null) {
      setIsDarkMode(savedMode === "true");
    }
  }, []);

  const calcularInteresCompuesto = React.useCallback(() => {
    let dineroActual = parseFloat(capitalInicial);
    const anios = parseInt(anos);
    const tasa = parseFloat(tasaInteres);
    const adicionMensual = parseFloat(aportacionMensual);
    const data = [];

    for (let i = 0; i < anios * 12; i++) {
      dineroActual += adicionMensual;
      dineroActual *= 1 + tasa / 100 / 12;
      if (i % 12 === 11) {
        data.push(dineroActual.toFixed(2));
      }
    }

    const totalGanado =
      dineroActual - (parseFloat(capitalInicial) + adicionMensual * anios * 12);
    setResultado(dineroActual.toFixed(2));
    setGanado(totalGanado.toFixed(2));
    setDataChart(data);
  }, [capitalInicial, aportacionMensual, anos, tasaInteres]);

  useEffect(() => {
    calcularInteresCompuesto();
  }, [calcularInteresCompuesto]);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartSize({
          width: chartContainerRef.current.offsetWidth,
          height: chartContainerRef.current.offsetHeight,
        });
      }
    };

    handleResize(); // Initial size
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleIncrement = (setter, value, step) => () => {
    setter((prevValue) => {
      const newValue = parseFloat(prevValue) + step;
      return newValue.toString();
    });
  };

  const handleDecrement = (setter, value, step) => () => {
    setter((prevValue) => {
      const newValue = Math.max(0, parseFloat(prevValue) - step);
      return newValue.toString();
    });
  };

  // Preparar los datos para el gráfico
  const chartData = {
    labels: Array.from({ length: anos }, (_, i) => i + 1),
    datasets: [
      {
        label: "Balance final",
        data: dataChart,
        backgroundColor: "rgba(75, 192, 192, 1)",
        borderColor: "#ffffff",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: getChartColors().text,
          font: {
            family: "'Poppins', sans-serif",
          },
        },
        grid: {
          color: getChartColors().grid,
        },
      },
      x: {
        ticks: {
          color: getChartColors().text,
          font: {
            family: "'Poppins', sans-serif",
          },
        },
        grid: {
          color: getChartColors().grid,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: getChartColors().text,
          font: {
            family: "'Poppins', sans-serif",
          },
        },
      },
      title: {
        display: true,
        text: "Evolución de tu inversión",
        color: getChartColors().text,
        font: {
          family: "'Poppins', sans-serif",
        },
      },
      tooltip: {
        backgroundColor: getChartColors().tooltip.background,
        titleColor: getChartColors().tooltip.text,
        bodyColor: getChartColors().tooltip.text,
        borderColor: getChartColors().tooltip.border,
        borderWidth: 1,
        callbacks: {
          title: function (tooltipItems) {
            return "Año " + tooltipItems[0].label;
          },
        },
      },
    },
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: getChartColors().text,
          font: {
            family: "'Poppins', sans-serif",
          },
        },
      },
      title: {
        display: true,
        text: "Distribución de tu inversión",
        color: getChartColors().text,
        font: {
          family: "'Poppins', sans-serif",
        },
      },
      tooltip: {
        backgroundColor: getChartColors().tooltip.background,
        titleColor: getChartColors().tooltip.text,
        bodyColor: getChartColors().tooltip.text,
        borderColor: getChartColors().tooltip.border,
        borderWidth: 1,
      },
    },
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>
            Calculadora de Interés Compuesto - Descubre Cómo Crecen Tus
            Inversiones
          </title>
          <meta
            name="description"
            content="Calculadora de interés compuesto para calcular fácilmente cómo crecen tus inversiones a lo largo del tiempo."
          />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: [
                {
                  "@type": "Question",
                  name: "¿Qué es el interés compuesto?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "El interés compuesto es el proceso de ganar intereses sobre el capital inicial y sobre los intereses acumulados de periodos anteriores, lo que permite que el dinero crezca exponencialmente con el tiempo.",
                  },
                },
                {
                  "@type": "Question",
                  name: "¿Cómo funciona la calculadora de interés compuesto?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "La calculadora de interés compuesto toma en cuenta el capital inicial, la tasa de interés, el periodo de tiempo y la frecuencia de capitalización para calcular cómo crecerá tu inversión a lo largo del tiempo.",
                  },
                },
                {
                  "@type": "Question",
                  name: "¿Cuál es la diferencia entre interés simple e interés compuesto?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "La diferencia principal es que el interés simple solo genera rendimientos sobre el capital inicial, mientras que el interés compuesto genera rendimientos tanto sobre el capital inicial como sobre los intereses acumulados.",
                  },
                },
                {
                  "@type": "Question",
                  name: "¿Por qué es importante empezar a invertir temprano?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "Invertir temprano permite que el interés compuesto tenga más tiempo para trabajar, lo que maximiza el crecimiento exponencial de tus inversiones a largo plazo.",
                  },
                },
              ],
            })}
          </script>
        </Helmet>
        <header className="App-header">
          <h1 className="app-title">
            Calculadora de Interés Compuesto para tu cartera de inversión
          </h1>
          <p className="app-description">
            Descubre cómo crecerán tus inversiones con el interés compuesto.
            Aumenta tus ganancias y alcanza tus objetivos financieros.
          </p>
        </header>
        <div className="calculadoraContainer ">
          <div className="calculadoraValores bento-border">
            <div className="calculadoraComponent">
              <div className="variablesInteres">
                <div className="input-group">
                  <label htmlFor="capitalInicial">Capital inicial:</label>
                  <div className="input-wrapper">
                    <div className="input-currency-wrapper">
                      <input
                        type="number"
                        step="1000"
                        value={capitalInicial}
                        onChange={handleInputChange(setCapitalInicial)}
                        className="form-control with-currency"
                        required
                        id="capitalInicial"
                      />
                      <span className="currency-symbol">€</span>
                    </div>
                    <div className="input-buttons">
                      <button
                        onClick={handleIncrement(
                          setCapitalInicial,
                          capitalInicial,
                          1000
                        )}
                      >
                        +
                      </button>
                      <button
                        onClick={handleDecrement(
                          setCapitalInicial,
                          capitalInicial,
                          1000
                        )}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="aportacionMensual">Aportación mensual:</label>
                  <div className="input-wrapper">
                    <div className="input-currency-wrapper">
                      <input
                        type="number"
                        step="100"
                        value={aportacionMensual}
                        onChange={handleInputChange(setAportacionMensual)}
                        className="form-control with-currency"
                        id="aportacionMensual"
                      />
                      <span className="currency-symbol">€</span>
                    </div>
                    <div className="input-buttons">
                      <button
                        onClick={handleIncrement(
                          setAportacionMensual,
                          aportacionMensual,
                          100
                        )}
                      >
                        +
                      </button>
                      <button
                        onClick={handleDecrement(
                          setAportacionMensual,
                          aportacionMensual,
                          100
                        )}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="anos">Años:</label>
                  <div className="input-wrapper-no-currency">
                    <div className="input-no-currency-wrapper">
                      <input
                        type="number"
                        step="1"
                        min="1"
                        value={anos}
                        onChange={handleInputChange(setAnos)}
                        className="form-control"
                        id="anos"
                      />
                    </div>
                    <div className="input-buttons">
                      <button onClick={handleIncrement(setAnos, anos, 1)}>
                        +
                      </button>
                      <button onClick={handleDecrement(setAnos, anos, 1)}>
                        -
                      </button>
                    </div>
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="tasaInteres">Tasa de interés (%):</label>
                  <div className="input-wrapper-no-currency">
                    <div className="input-no-currency-wrapper">
                      <input
                        type="number"
                        step="1"
                        value={tasaInteres}
                        onChange={handleInputChange(setTasaInteres)}
                        className="form-control"
                        id="tasaInteres"
                      />
                    </div>
                    <div className="input-buttons">
                      <button
                        onClick={handleIncrement(
                          setTasaInteres,
                          tasaInteres,
                          1
                        )}
                      >
                        +
                      </button>
                      <button
                        onClick={handleDecrement(
                          setTasaInteres,
                          tasaInteres,
                          1
                        )}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>

                <div className="resultadoInteres">
                  {resultado !== null && (
                    <>
                      <h3>Resumen de tu inversión</h3>
                      <div className="resultado-item">
                        <span className="resultado-label">
                          Valor final de la cartera
                        </span>
                        <span className="resultado-value">{resultado} €</span>
                      </div>
                      <div className="resultado-item">
                        <span className="resultado-label">
                          Ganancias totales
                        </span>
                        <span className="resultado-value-ganado">
                          {ganado} €
                        </span>
                      </div>
                      <div className="resultado-item">
                        <span className="resultado-label">
                          Plazo de inversión
                        </span>
                        <span className="resultado-value-anos">
                          {anos} años
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="calculadoraGrafico bento-border">
            {dataChart.length > 0 && (
              <div
                id="grafico"
                ref={chartContainerRef}
                style={{ width: "100%", height: "400px" }}
              >
                <Bar
                  data={chartData}
                  options={chartOptions}
                  width={chartSize.width}
                  height={chartSize.height}
                />
              </div>
            )}
          </div>
          <div className="calculadoraPie bento-border">
            {dataChart.length > 0 && (
              <div
                id="grafico-pie"
                style={{ width: "100%", height: "400px", marginTop: "20px" }}
              >
                <Pie
                  data={{
                    labels: [
                      "Inversión inicial",
                      "Cantidad invertida",
                      "Ganancias",
                    ],
                    datasets: [
                      {
                        data: [
                          parseFloat(capitalInicial),
                          parseFloat(aportacionMensual) * parseInt(anos) * 12,
                          parseFloat(ganado),
                        ],
                        backgroundColor: ["#a58dce", "#4bb9c3", "#6DD08C"],
                        borderColor: [
                          getChartColors().border,
                          getChartColors().border,
                          getChartColors().border,
                        ],
                        borderWidth: 0.75,
                      },
                    ],
                  }}
                  options={pieOptions}
                />
              </div>
            )}
          </div>
        </div>

        <CalculadoraFaqs />
      </div>
    </HelmetProvider>
  );
};

export default Calculadora;
