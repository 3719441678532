import React from "react";
import { Bar } from "react-chartjs-2";

const ExpenseSummary = ({ expenses }) => {
  // Agrupar gastos por categoría
  const expensesByCategory = expenses.reduce((acc, expense) => {
    if (!acc[expense.category]) {
      acc[expense.category] = 0;
    }
    acc[expense.category] += expense.amount;
    return acc;
  }, {});

  // Preparar datos para el gráfico
  const chartData = {
    labels: Object.keys(expensesByCategory),
    datasets: [
      {
        label: "Gastos por categoría",
        data: Object.values(expensesByCategory),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
        ],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Distribución de Gastos por Categoría",
      },
    },
  };

  return (
    <div className="expense-summary">
      <div className="summary-chart">
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div className="summary-table">
        <table>
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Total</th>
              <th>% del Total</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(expensesByCategory).map(([category, amount]) => {
              const percentage = (
                (amount / expenses.reduce((acc, exp) => acc + exp.amount, 0)) *
                100
              ).toFixed(1);
              return (
                <tr key={category}>
                  <td>{category}</td>
                  <td>{amount.toFixed(2)}€</td>
                  <td>{percentage}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpenseSummary;
