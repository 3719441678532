import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Calculadora from "./components/Calculadora";
import "./index.css";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./components/Register";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";

import { HelmetProvider } from "react-helmet-async";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import LegalNotice from "./components/LegalNotice";
import Homepage from "./components/Homepage";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Header />

          <main>
            <Routes>
              <Route path="/home" element={<Homepage />} />
              <Route path="/calculator" element={<Calculadora />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="/legal" element={<LegalNotice />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
