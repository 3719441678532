import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/logo.png";
import "./header.css";

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/auth/profile`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setUserData(response.data);
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsAuthenticated(false);
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="header">
      <Link to="/home">
        <img src={logo} alt="logo" className="logo" />
      </Link>
      <nav className="menu">
        <ul>
          <li>
            <Link to="/calculator">Calculadora</Link>
          </li>
          <li>
            {isAuthenticated ? (
              <Link to="/dashboard" className="user-avatar-header">
                <img
                  src={`https://api.dicebear.com/7.x/initials/svg?seed=${userData?.username?.charAt(
                    0
                  )}&backgroundColor=ffffff&textColor=000000`}
                  alt="Avatar"
                />
              </Link>
            ) : (
              <Link to="/login" className="login-button">
                Iniciar sesión
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
