import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "./register.css";

function Register() {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        userData
      );

      setMessage(response.data.message);
      setUserData({ username: "", email: "", password: "" });

      setMessage("¡Registro exitoso! Redirigiendo al inicio de sesión...");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setMessage(
        error.response.data.message || "Ocurrió un error durante el registro"
      );
    }
  };

  return (
    <div className="register-wrapper">
      <div className="register-container bento-border">
        <h2>Registro</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            value={userData.username}
            onChange={handleChange}
            placeholder="Nombre de usuario"
            required
          />
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            placeholder="Correo electrónico"
            required
          />
          <input
            type="password"
            name="password"
            value={userData.password}
            onChange={handleChange}
            placeholder="Contraseña"
            required
          />
          <button type="submit">Registrarse</button>
        </form>
        {message && <p>{message}</p>}
        ¿Ya tienes una cuenta?{" "}
        <Link to="/login" style={{ textDecoration: "underline" }}>
          Inicia sesión
        </Link>
      </div>
    </div>
  );
}

export default Register;
