import React from "react";
import { Link } from "react-router-dom";
import { FaBalanceScale } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-links">
        <Link to="/legal" className="legal-link">
          <FaBalanceScale /> Aviso Legal
        </Link>
      </div>
      <div className="copyright">
        Copyright © {new Date().getFullYear()} myfinancebrain.com
      </div>
      <div className="social-links">
        <div></div>
      </div>
    </div>
  );
};

export default Footer;
