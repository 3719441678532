import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import "./Chatbot.css";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const predefinedQuestions = [
    "¿Cuáles son los principios básicos para empezar a invertir?",
    "¿Cómo puedo crear un presupuesto mensual efectivo?",
    "¿Qué es la estrategia de Montecarlo?",
    "¿Cuál es la diferencia entre interés simple y compuesto?",
  ];

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise && window.MathJax.typesetPromise();
    }
  }, [messages]);

  const handleSubmit = async (e, predefinedMessage = null) => {
    if (e) e.preventDefault();
    const messageToSend = predefinedMessage || inputMessage;
    if (!messageToSend.trim() || isLoading) return;

    try {
      setIsLoading(true);
      setInputMessage("");

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          message: messageToSend,
        }),
      });

      if (!response.ok) throw new Error("Error en la respuesta del servidor");

      const data = await response.json();

      setMessages((prev) => [
        ...prev,
        { role: "user", content: messageToSend },
        { role: "assistant", content: data.message },
      ]);
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content:
            "Lo siento, ha ocurrido un error. Por favor, inténtalo de nuevo.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/analyze-file`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Error al analizar el archivo");

      const data = await response.json();

      setMessages((prev) => [
        ...prev,
        { role: "user", content: `Analizando archivo: ${file.name}` },
        { role: "assistant", content: data.message },
      ]);
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content:
            "Error al analizar el archivo. Por favor, inténtalo de nuevo.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const sanitizeAndRenderHTML = (content) => {
    const sanitizedHTML = DOMPurify.sanitize(content, {
      ALLOWED_TAGS: [
        "blockquote",
        "p",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "ul",
        "ol",
        "li",
        "strong",
        "em",
        "b",
        "i",
        "a",
        "br",
        "span",
        "div",
      ],
      ADD_ATTR: ["class"],
    });
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
  };

  return (
    <div className="chatbot-container">
      {messages.length === 0 && (
        <div className="predefined-questions">
          {predefinedQuestions.map((question, index) => (
            <button
              key={index}
              onClick={() => handleSubmit(null, question)}
              className="predefined-question-btn"
              disabled={isLoading}
            >
              {question}
            </button>
          ))}
        </div>
      )}

      <div className={`chat-messages ${messages.length === 0 ? "empty" : ""}`}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            {message.role === "assistant"
              ? sanitizeAndRenderHTML(message.content)
              : message.content}
          </div>
        ))}
        {isLoading && (
          <div className="loading">
            <div className="spinner"></div>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit} className="chat-input-form">
        <div className="chat-input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Escribe tu pregunta..."
            disabled={isLoading}
          />
          <label className="file-upload-label">
            📎
            <input
              type="file"
              onChange={handleFileUpload}
              accept=".pdf,.txt,.doc,.docx"
              style={{ display: "none" }}
              disabled={isLoading}
            />
          </label>
        </div>
        <button type="submit" disabled={isLoading || !inputMessage.trim()}>
          Enviar
        </button>
      </form>
    </div>
  );
};

export default Chatbot;
