import React, { useState, useEffect, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FinancialProjection = ({
  currentIncomes,
  currentExpenses,
  currentInvestments,
}) => {
  const [formData, setFormData] = useState({
    initialCapital: 0,
    monthlyIncome: currentIncomes || 0,
    monthlyExpenses: currentExpenses || 0,
    monthlyInvestment: currentInvestments || 0,
    startDate: new Date().toISOString().split("T")[0],
    yearsToProject: 25,
    expectedReturn: 7,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      monthlyIncome: currentIncomes,
      monthlyExpenses: currentExpenses,
      monthlyInvestment: currentInvestments,
    }));
  }, [currentIncomes, currentExpenses, currentInvestments]);

  const [projectionData, setProjectionData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateProjection = useCallback(() => {
    const years = Array.from(
      { length: formData.yearsToProject + 1 },
      (_, i) => i
    );

    const projectedValues = years.map((currentYear) => {
      // 1. Cálculo de ahorros (sin interés)
      const monthlyNet =
        formData.monthlyIncome -
        formData.monthlyExpenses -
        formData.monthlyInvestment;
      const totalSavings = monthlyNet * 12 * currentYear;

      // 2. Cálculo de inversiones con interés compuesto (usando la fórmula del Dashboard)
      let dineroActual = parseFloat(formData.initialCapital);
      const tasa = formData.expectedReturn;
      const adicionMensual = formData.monthlyInvestment;

      // Calculamos mes a mes hasta el año actual
      for (let i = 0; i < currentYear * 12; i++) {
        dineroActual += adicionMensual;
        dineroActual *= 1 + tasa / 100 / 12;
      }

      const investmentValue = dineroActual;
      const totalValue = totalSavings + investmentValue;

      return {
        year: currentYear,
        value: totalValue.toFixed(2),
        savings: totalSavings.toFixed(2),
        investments: investmentValue.toFixed(2),
      };
    });

    return {
      labels: years.map((year) => `Año ${year}`),
      datasets: [
        {
          label: "Ahorros sin interés",
          data: projectedValues.map((item) => item.savings),
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
        {
          label: "Inversiones con interés compuesto",
          data: projectedValues.map((item) => item.investments),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          label: "Total",
          data: projectedValues.map((item) => item.value),
          fill: false,
          borderColor: "rgb(153, 102, 255)",
          tension: 0.1,
        },
      ],
    };
  }, [formData]);

  useEffect(() => {
    setProjectionData(calculateProjection());
  }, [calculateProjection]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Proyección Financiera a Futuro",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return new Intl.NumberFormat("es-ES", {
              style: "currency",
              currency: "EUR",
            }).format(value);
          },
        },
      },
    },
  };

  return (
    <div className="financial-projection">
      <div className="projection-form">
        <h2>Calculadora de Proyección Financiera</h2>
        <div className="form-group">
          <label>Patrimonio Actual (€)</label>
          <input
            type="number"
            name="initialCapital"
            value={formData.initialCapital}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Ingresos Mensuales (€)</label>
          <input
            type="number"
            name="monthlyIncome"
            value={formData.monthlyIncome}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Gastos Mensuales (€)</label>
          <input
            type="number"
            name="monthlyExpenses"
            value={formData.monthlyExpenses}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Inversión Mensual (€)</label>
          <input
            type="number"
            name="monthlyInvestment"
            value={formData.monthlyInvestment}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Fecha de Inicio</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Años a Proyectar</label>
          <input
            type="number"
            name="yearsToProject"
            value={formData.yearsToProject}
            min="1"
            max="50"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Retorno Anual Esperado (%)</label>
          <input
            type="number"
            name="expectedReturn"
            value={formData.expectedReturn}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="projection-chart">
        {projectionData && <Line options={options} data={projectionData} />}
      </div>
    </div>
  );
};

export default FinancialProjection;
