import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./forgotPassword.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        { email }
      );
      setIsEmailSent(true);
      setMessage(
        "Se ha enviado un enlace de recuperación a tu correo electrónico"
      );
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          "Ocurrió un error al procesar tu solicitud"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password-wrapper">
      <div className="forgot-password-container bento-border">
        <h2>Recuperar Contraseña</h2>
        {!isEmailSent ? (
          <>
            <p className="instructions">
              Ingresa tu correo electrónico y te enviaremos las instrucciones
              para restablecer tu contraseña.
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Correo electrónico"
                required
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <div className="loader"></div>
                    <span>Enviando...</span>
                  </>
                ) : (
                  "Enviar instrucciones"
                )}
              </button>
            </form>
          </>
        ) : (
          <div className="success-message">
            <p>✅ {message}</p>
            <p>Revisa tu bandeja de entrada y sigue las instrucciones.</p>
          </div>
        )}
        {message && !isEmailSent && <p className="error-message">{message}</p>}
        <div className="auth-links">
          <Link to="/login">Volver al inicio de sesión</Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
