import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "./login.css";

function Login() {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (process.env.NODE_ENV === "development") {
      console.log("🔍 Intentando login con:", {
        url: `${process.env.REACT_APP_API_URL}/auth/login`,
      });
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        userData
      );
      console.log("✅ Login exitoso");

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.userId);
      setMessage("Inicio de sesión exitoso");
      navigate("/dashboard");
    } catch (error) {
      console.error("❌ Error en login");
      setMessage(
        error.response?.data?.message ||
          "Ocurrió un error durante el inicio de sesión"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-wrapper ">
      <div className="login-container bento-border">
        <h2>Iniciar Sesión</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            placeholder="Correo electrónico"
            required
          />
          <input
            type="password"
            name="password"
            value={userData.password}
            onChange={handleChange}
            placeholder="Contraseña"
            required
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? (
              <>
                <div className="loader"></div>
                <span>Cargando...</span>
              </>
            ) : (
              "Iniciar Sesión"
            )}
          </button>
        </form>
        {message && <p>{message}</p>}
        <div className="auth-links">
          <div>
            ¿No tienes una cuenta?{" "}
            <Link to="/register" style={{ textDecoration: "underline" }}>
              Regístrate
            </Link>
          </div>
          <div>
            <Link to="/forgot-password" style={{ textDecoration: "underline" }}>
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
