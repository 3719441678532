import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "./dashboard.css";
import {
  FaPencilAlt,
  FaPlus,
  FaTrash,
  FaSun,
  FaMoon,
  FaFilter,
  FaChartLine,
  FaProjectDiagram,
} from "react-icons/fa";
// Importar los componentes necesarios de Chart.js
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import Chatbot from "./Chatbot";
import ExpenseSummary from "./ExpenseSummary";
import FinancialProjection from "./FinancialProjection";

// Registrar los componentes de Chart.js..
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [editingIncomeIndex, setEditingIncomeIndex] = useState(null);
  const [editingExpenseIndex, setEditingExpenseIndex] = useState(null);
  const [showNewIncomeForm, setShowNewIncomeForm] = useState(false);
  const [showNewExpenseForm, setShowNewExpenseForm] = useState(false);
  const [newIncomeName, setNewIncomeName] = useState("");
  const [newIncomeAmount, setNewIncomeAmount] = useState("");
  const [newExpenseName, setNewExpenseName] = useState("");
  const [newExpenseAmount, setNewExpenseAmount] = useState("");
  const [confirmationMessages, setConfirmationMessages] = useState({
    incomes: "",
    expenses: "",
    investments: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [editingInvestmentIndex, setEditingInvestmentIndex] = useState(null);
  const [showNewInvestmentForm, setShowNewInvestmentForm] = useState(false);
  const [newInvestmentName, setNewInvestmentName] = useState("");
  const [newInvestmentAmount, setNewInvestmentAmount] = useState("");
  const [capitalInicial, setCapitalInicial] = useState(1000);
  const [aportacionMensual, setAportacionMensual] = useState(100);
  const [anos, setAnos] = useState(10);
  const [tasaInteres, setTasaInteres] = useState(8);
  const [resultado, setResultado] = useState(null);
  const [ganado, setGanado] = useState(null);
  const [dataChart, setDataChart] = useState([]);
  const [considerarInflacion, setConsiderarInflacion] = useState(false);
  const [tasaInflacion, setTasaInflacion] = useState(2); // Inflación media histórica aproximada
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  const [showExpenseSummary, setShowExpenseSummary] = useState(false);
  const [showFinancialProjection, setShowFinancialProjection] = useState(false);

  const categories = [
    "Alimentación",
    "Transporte",
    "Vivienda",
    "Servicios",
    "Entretenimiento",
    "Salud",
    "Educación",
    "Ropa",
    "Tecnología",
    "Otros",
  ];

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        handleLogout();
        return;
      }

      try {
        // Añadir un pequeño delay para asegurar que el token está disponible
        await new Promise((resolve) => setTimeout(resolve, 100));

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            // Añadir timeout más largo
            timeout: 30000, // Aumentado a 30 segundos
          }
        );

        if (response.data) {
          setUserData(response.data);
          setIncomes(response.data.incomes || []);
          setExpenses(
            Array.isArray(response.data.expenses) ? response.data.expenses : []
          );
          setInvestments(
            Array.isArray(response.data.investments)
              ? response.data.investments
              : []
          );
        } else {
          throw new Error("No se recibieron datos del usuario");
        }
      } catch (err) {
        if (err.code === "ECONNABORTED") {
          setError("Tiempo de espera agotado. Por favor, intenta de nuevo.");
        } else if (err.response?.status === 401) {
          if (err.response?.data?.expired) {
            setError(
              "Tu sesión ha expirado. Por favor, inicia sesión nuevamente."
            );
            setTimeout(() => {
              handleLogout();
            }, 2000);
          } else {
            handleLogout();
          }
        } else {
          setError(
            err.response?.data?.message ||
              "Error al cargar los datos del usuario"
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [handleLogout]);

  const saveFinancialData = async (
    updatedIncomes = incomes,
    updatedExpenses = expenses,
    updatedInvestments = investments
  ) => {
    setIsSaving(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/auth/update-financial`,
        data: {
          incomes: updatedIncomes,
          expenses: updatedExpenses,
          investments: updatedInvestments,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  const handleIncomeEdit = (index) => {
    setEditingIncomeIndex(index);
  };

  const handleIncomeUpdate = (index, field, value) => {
    const updatedIncomes = [...incomes];
    updatedIncomes[index][field] = value;
    setIncomes(updatedIncomes);
  };

  const handleIncomeSave = async (index) => {
    setEditingIncomeIndex(null);
    try {
      const response = await saveFinancialData();
      if (response.success) {
        showConfirmationMessage("Ingreso actualizado con éxito", "incomes");
      } else {
        setError("Error al actualizar el ingreso");
      }
    } catch (err) {
      console.error("Error al actualizar el ingreso:", err);
      setError("Error al actualizar el ingreso");
    }
  };

  const handleIncomeDelete = async (index) => {
    try {
      const updatedIncomes = incomes.filter((_, i) => i !== index);
      const response = await saveFinancialData(updatedIncomes);

      if (response.success) {
        setIncomes(response.incomes);
        showConfirmationMessage("Ingreso eliminado con éxito", "incomes");
      } else {
        setError("Error al eliminar el ingreso");
      }
    } catch (err) {
      console.error("Error al eliminar el ingreso:", err);
      setError("Error al eliminar el ingreso");
    }
  };

  const handleExpenseEdit = (index) => {
    setEditingExpenseIndex(index);
  };

  const handleExpenseUpdate = (index, field, value) => {
    const updatedExpenses = [...expenses];
    updatedExpenses[index][field] = value;
    setExpenses(updatedExpenses);
  };

  const handleExpenseSave = async (index) => {
    setEditingExpenseIndex(null);
    try {
      const response = await saveFinancialData();
      if (response.success) {
        showConfirmationMessage("Gasto actualizado con éxito", "expenses");
      } else {
        setError("Error al actualizar el gasto");
      }
    } catch (err) {
      console.error("Error al actualizar el gasto:", err);
      setError("Error al actualizar el gasto");
    }
  };

  const handleExpenseDelete = async (index) => {
    try {
      const updatedExpenses = expenses.filter((_, i) => i !== index);
      const response = await saveFinancialData(undefined, updatedExpenses);

      if (response.success) {
        setExpenses(response.expenses);
        showConfirmationMessage("Gasto eliminado con éxito", "expenses");
      } else {
        setError("Error al eliminar el gasto");
      }
    } catch (err) {
      console.error("Error al eliminar el gasto:", err);
      setError("Error al eliminar el gasto");
    }
  };

  const toggleNewIncomeForm = () => {
    setShowNewIncomeForm(!showNewIncomeForm);
    setNewIncomeName("");
    setNewIncomeAmount("");
  };

  const toggleNewExpenseForm = () => {
    setShowNewExpenseForm((prev) => !prev);
  };

  const showConfirmationMessage = (message, section) => {
    setConfirmationMessages((prev) => ({
      ...prev,
      [section]: message,
    }));
    setTimeout(() => {
      setConfirmationMessages((prev) => ({
        ...prev,
        [section]: "",
      }));
    }, 3000);
  };

  const addIncome = async () => {
    if (newIncomeName && newIncomeAmount) {
      const newIncome = {
        name: newIncomeName,
        amount: parseFloat(newIncomeAmount),
      };
      const updatedIncomes = [...incomes, newIncome];

      try {
        const response = await saveFinancialData(updatedIncomes);
        if (response.success) {
          setIncomes(response.incomes);
          setNewIncomeName("");
          setNewIncomeAmount("");
          setShowNewIncomeForm(false);
          showConfirmationMessage("Nuevo ingreso añadido con éxito", "incomes");
        } else {
          setError("Error al guardar el nuevo ingreso");
        }
      } catch (err) {
        console.error("Error al guardar el nuevo ingreso:", err);
        setError("Error al guardar el nuevo ingreso");
      }
    }
  };

  const addExpense = async () => {
    if (newExpenseName && newExpenseAmount) {
      setIsSaving(true);
      try {
        const categoryResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/categorize`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ expenseName: newExpenseName }),
          }
        );

        if (!categoryResponse.ok) {
          throw new Error("Error en categorización");
        }

        const categoryData = await categoryResponse.json();

        const newExpense = {
          name: newExpenseName,
          amount: parseFloat(newExpenseAmount),
          category: categoryData.category || "Otros",
        };

        const updatedExpenses = [...expenses, newExpense];
        const response = await saveFinancialData(undefined, updatedExpenses);

        if (response.success) {
          setExpenses(response.expenses);
          setNewExpenseName("");
          setNewExpenseAmount("");
          setShowNewExpenseForm(false);
          showConfirmationMessage("Gasto añadido con éxito", "expenses");
        } else {
          throw new Error(response.message || "Error al guardar el gasto");
        }
      } catch (error) {
        setError(error.message || "Error al añadir el gasto");
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleInvestmentEdit = (index) => {
    setEditingInvestmentIndex(index);
  };

  const handleInvestmentUpdate = (index, field, value) => {
    const updatedInvestments = [...investments];
    updatedInvestments[index][field] = value;
    setInvestments(updatedInvestments);
  };

  const handleInvestmentSave = async (index) => {
    setEditingInvestmentIndex(null);
    try {
      const response = await saveFinancialData(
        undefined,
        undefined,
        investments
      );
      if (response.success) {
        showConfirmationMessage(
          "Inversión actualizada con éxito",
          "investments"
        );
      } else {
        setError("Error al actualizar la inversión");
      }
    } catch (err) {
      console.error("Error al actualizar la inversión:", err);
      setError("Error al actualizar la inversión");
    }
  };

  const handleInvestmentDelete = async (index) => {
    try {
      const updatedInvestments = investments.filter((_, i) => i !== index);
      const response = await saveFinancialData(
        undefined,
        undefined,
        updatedInvestments
      );

      if (response.success) {
        setInvestments(response.investments);
        showConfirmationMessage("Inversión eliminada con éxito", "investments");
      } else {
        setError("Error al eliminar la inversión");
      }
    } catch (err) {
      console.error("Error al eliminar la inversión:", err);
      setError("Error al eliminar la inversión");
    }
  };

  const toggleNewInvestmentForm = () => {
    setShowNewInvestmentForm(!showNewInvestmentForm);
    setNewInvestmentName("");
    setNewInvestmentAmount("");
  };

  const addInvestment = async () => {
    if (newInvestmentName && newInvestmentAmount) {
      const newInvestment = {
        name: newInvestmentName,
        amount: parseFloat(newInvestmentAmount),
      };
      const updatedInvestments = [...investments, newInvestment];

      try {
        console.log("💾 Guardando nueva inversión:", newInvestment);
        const response = await saveFinancialData(
          undefined,
          undefined,
          updatedInvestments
        );

        if (response.success) {
          setInvestments(response.investments);
          setNewInvestmentName("");
          setNewInvestmentAmount("");
          setShowNewInvestmentForm(false);
          showConfirmationMessage(
            "Nueva inversión añadida con éxito",
            "investments"
          );
        } else {
          setError("Error al guardar la nueva inversión");
        }
      } catch (err) {
        console.error("❌ Error al guardar la nueva inversión:", err);
        setError("Error al guardar la nueva inversión");
      }
    } else {
      setError("Por favor, completa todos los campos de la inversión");
    }
  };

  // Función para generar un color aleatorio en formato hexadecimal
  const generateRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  // Función para generar un array de colores
  const generateColorArray = (length) => {
    const baseColors = [
      "#eab8ff",
      "#d17aff",
      "#a857ff",
      "#6d2eff",
      "#390b7f",
      "#FF9F40",
    ];

    if (length <= baseColors.length) {
      return baseColors.slice(0, length);
    }

    const additionalColors = Array(length - baseColors.length)
      .fill()
      .map(() => generateRandomColor());
    return [...baseColors, ...additionalColors];
  };

  // Función para preparar los datos del gráfico
  const prepareChartData = (expenses = incomes) => {
    const labels = expenses.map((expense) => expense.name);
    const data = expenses.map((expense) => expense.amount);
    const colors = generateColorArray(data.length);

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
          hoverBackgroundColor: colors.map((color) => {
            // Crear una versión más clara del color para el hover
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            return `rgba(${r}, ${g}, ${b}, 0.8)`;
          }),
          borderWidth: 0.75,
        },
      ],
    };
  };

  // Opciones del gráfico
  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Oculta la leyenda
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce(
              (acc, data) => acc + data,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value}€ (${percentage}%)`;
          },
        },
      },
    },
  };

  // Modifica la función prepareBarChartData para incluir inversiones
  const prepareBarChartData = () => {
    const totalIncome = incomes.reduce((acc, income) => acc + income.amount, 0);
    const totalExpense = expenses.reduce(
      (acc, expense) => acc + expense.amount,
      0
    );
    const totalInvestment = investments.reduce(
      (acc, investment) => acc + investment.amount,
      0
    );

    return {
      labels: ["Ingresos", "Gastos", "Inversiones"],
      datasets: [
        {
          label: "Cantidad en €",
          data: [totalIncome, totalExpense, totalInvestment],
          backgroundColor: ["#6dd08c", "#f084a1", "#a857ff"], // Verde, Rojo, Morado
          borderColor: ["white", "white", "white"],
          borderWidth: 0.75,
        },
      ],
    };
  };

  // Función para obtener colores con buen contraste
  const getChartColors = () => {
    return {
      text: isDarkMode ? "#ffffff" : "#000000",
      grid: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
      tooltip: {
        background: isDarkMode
          ? "rgba(0, 0, 0, 0.8)"
          : "rgba(255, 255, 255, 0.8)",
        text: isDarkMode ? "#ffffff" : "#000000",
        border: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
      },
    };
  };

  // Actualiza las opciones del gráfico de barras del resumen
  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: getChartColors().tooltip.background,
        titleColor: getChartColors().tooltip.text,
        bodyColor: getChartColors().tooltip.text,
        borderColor: getChartColors().tooltip.border,
        borderWidth: 1,
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.formattedValue}€`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "€";
          },
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
      x: {
        ticks: {
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
    },
  };

  // Actualiza las opciones del gráfico de la calculadora
  const calculatorChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
      x: {
        ticks: {
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: getChartColors().text,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: getChartColors().tooltip.background,
        titleColor: getChartColors().tooltip.text,
        bodyColor: getChartColors().tooltip.text,
        borderColor: getChartColors().tooltip.border,
        borderWidth: 1,
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${parseInt(
              context.raw
            ).toLocaleString()}€`;
          },
        },
      },
    },
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem("token");
      console.log("🔑 Token:", token);

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/auth/delete-account`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("✅ Respuesta:", response.data);

      if (response.data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        navigate("/login");
      } else {
        setError("Error al eliminar la cuenta");
      }
    } catch (err) {
      console.error("❌ Error completo:", err);
      setError(err.response?.data?.message || "Error al eliminar la cuenta");
    }
  };

  const calcularInteresCompuesto = useCallback(() => {
    let dineroActual = parseFloat(capitalInicial);
    const anios = parseInt(anos);
    const tasa = parseFloat(tasaInteres);
    const adicionMensual = parseFloat(aportacionMensual);
    const data = [];
    const dataReal = []; // Valor ajustado por inflación

    for (let i = 0; i < anios * 12; i++) {
      dineroActual += adicionMensual;
      dineroActual *= 1 + tasa / 100 / 12;

      if (i % 12 === 11) {
        data.push(dineroActual.toFixed(2));

        if (considerarInflacion) {
          // Calculamos el valor real ajustado por inflación
          const valorReal =
            dineroActual /
            Math.pow(1 + tasaInflacion / 100, Math.floor(i / 12) + 1);
          dataReal.push(valorReal.toFixed(2));
        }
      }
    }

    let totalGanado;
    if (considerarInflacion) {
      // Ajustamos las ganancias considerando la inflación
      const valorFinalReal = parseFloat(dataReal[dataReal.length - 1]);
      const inversionTotalNominal =
        parseFloat(capitalInicial) + adicionMensual * anios * 12;
      const inversionTotalReal =
        inversionTotalNominal / Math.pow(1 + tasaInflacion / 100, anios);
      totalGanado = valorFinalReal - inversionTotalReal;
      setResultado(valorFinalReal.toFixed(2));
    } else {
      totalGanado =
        dineroActual -
        (parseFloat(capitalInicial) + adicionMensual * anios * 12);
      setResultado(dineroActual.toFixed(2));
    }

    setGanado(totalGanado.toFixed(2));
    setDataChart({
      nominal: data,
      real: considerarInflacion ? dataReal : null,
    });
  }, [
    capitalInicial,
    aportacionMensual,
    anos,
    tasaInteres,
    considerarInflacion,
    tasaInflacion,
  ]);

  useEffect(() => {
    calcularInteresCompuesto();
  }, [calcularInteresCompuesto]);

  const handleIncrement = (setter, value, step) => () => {
    setter((prevValue) => {
      const newValue = parseFloat(prevValue) + step;
      return newValue.toString();
    });
  };

  const handleDecrement = (setter, value, step) => () => {
    setter((prevValue) => {
      const newValue = Math.max(0, parseFloat(prevValue) - step);
      return newValue.toString();
    });
  };

  const toggleTheme = () => {
    const root = document.documentElement;
    const newMode = !isDarkMode;

    if (newMode) {
      root.classList.remove("light-theme");
    } else {
      root.classList.add("light-theme");
    }

    setIsDarkMode(newMode);
    localStorage.setItem("darkMode", newMode);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem("darkMode");
    if (savedMode !== null) {
      const isDark = savedMode === "true";
      setIsDarkMode(isDark);
      if (!isDark) {
        document.documentElement.classList.add("light-theme");
      }
    }
  }, []);

  // Función para filtrar gastos por categoría
  const filteredExpenses =
    selectedCategory === "all"
      ? expenses
      : expenses.filter((expense) => expense.category === selectedCategory);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const popup = document.querySelector(".category-filter-popup");
      const filterIcon = document.querySelector(
        ".expenses-actions svg:last-child"
      ); // El icono de FaFilter

      if (
        showCategoryFilter &&
        popup &&
        !popup.contains(event.target) &&
        !filterIcon.contains(event.target)
      ) {
        setShowCategoryFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showCategoryFilter]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setShowCategoryFilter(false); // Cerrar el popup después de seleccionar
  };

  return (
    <div className="dashboard">
      <h1>Bienvenido a tu Dashboard, {userData?.username}</h1>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : userData ? (
        <div className="bento-grid">
          <div className="bento-item user-info bento-border">
            <div className="user-header">
              <div className="user-avatar">
                <img
                  src={`https://api.dicebear.com/7.x/initials/svg?seed=${userData.username.charAt(
                    0
                  )}&backgroundColor=ffffff&textColor=000000`}
                  alt="Avatar"
                />
              </div>
              <div className="user-details">
                <h2>Información del Usuario</h2>
                <div className="user-field">
                  <span className="field-label">Usuario</span>
                  <span className="field-value">{userData.username}</span>
                </div>
                <div className="user-field">
                  <span className="field-label">Email</span>
                  <span className="field-value">{userData.email}</span>
                </div>
                <div className="user-field">
                  <span className="field-label">Miembro desde</span>
                  <span className="field-value">
                    {new Date(userData.createdAt).toLocaleDateString("es-ES", {
                      year: "numeric",
                      month: "long",
                    })}
                  </span>
                </div>
                <div className="user-field forgot-password">
                  <Link to="/forgot-password" className="forgot-password-link">
                    ¿Has olvidado o quieres cambiar la contraseña?
                  </Link>
                </div>
              </div>
              <button
                className="theme-toggle-button"
                onClick={toggleTheme}
                aria-label={
                  isDarkMode ? "Cambiar a modo claro" : "Cambiar a modo oscuro"
                }
              >
                {isDarkMode ? <FaSun /> : <FaMoon />}
              </button>
            </div>
          </div>
          <div className="bento-item user-resume bento-border">
            <div className="resume-header">
              <h2>Resumen mensual</h2>
              <FaProjectDiagram
                onClick={() =>
                  setShowFinancialProjection(!showFinancialProjection)
                }
                title="Ver proyección financiera"
                className="projection-toggle"
              />
            </div>
            <Bar data={prepareBarChartData()} options={barChartOptions} />

            <p className="savings-total">
              Ahorro mensual:{" "}
              <strong>
                {(
                  incomes.reduce((acc, income) => acc + income.amount, 0) -
                  expenses.reduce((acc, expense) => acc + expense.amount, 0) -
                  investments.reduce(
                    (acc, investment) => acc + investment.amount,
                    0
                  )
                ).toFixed(2)}
                €
              </strong>
            </p>
          </div>
          {showFinancialProjection && (
            <div className="bento-item financial-projection-section bento-border">
              <h2>Proyección Financiera</h2>
              <FinancialProjection
                currentIncomes={incomes.reduce(
                  (acc, income) => acc + income.amount,
                  0
                )}
                currentExpenses={expenses.reduce(
                  (acc, expense) => acc + expense.amount,
                  0
                )}
                currentInvestments={investments.reduce(
                  (acc, investment) => acc + investment.amount,
                  0
                )}
              />
            </div>
          )}
          <div className="bento-item income-section bento-border">
            <h2>Ingresos mensuales</h2>

            <div className="income-content ">
              <div className="income-chart">
                {incomes.length > 0 ? (
                  <>
                    <Pie
                      data={prepareChartData(incomes)}
                      options={chartOptions}
                    />
                    <p className="income-total">
                      Total de ingresos:{" "}
                      <strong>
                        {incomes.reduce(
                          (acc, income) => acc + income.amount,
                          0
                        )}
                        €
                      </strong>
                    </p>
                  </>
                ) : (
                  <p>No hay ingresos registrados</p>
                )}
              </div>

              <div className="income-list">
                {incomes.map((income, index) => (
                  <div key={index} className="income-item">
                    {editingIncomeIndex === index ? (
                      <>
                        <input
                          type="text"
                          value={income.name}
                          onChange={(e) =>
                            handleIncomeUpdate(index, "name", e.target.value)
                          }
                        />
                        <input
                          type="number"
                          value={income.amount}
                          onChange={(e) =>
                            handleIncomeUpdate(index, "amount", e.target.value)
                          }
                        />
                        <button onClick={() => handleIncomeSave(index)}>
                          Guardar
                        </button>
                      </>
                    ) : (
                      <>
                        <span>
                          {income.name}: {income.amount}€
                        </span>
                        <div className="income-actions">
                          <FaPencilAlt
                            onClick={() => handleIncomeEdit(index)}
                          />
                          <FaTrash onClick={() => handleIncomeDelete(index)} />
                        </div>
                      </>
                    )}
                  </div>
                ))}
                <div className="add-income">
                  {showNewIncomeForm ? (
                    <>
                      <input
                        type="text"
                        value={newIncomeName}
                        onChange={(e) => setNewIncomeName(e.target.value)}
                        placeholder="Nombre del ingreso"
                      />
                      <input
                        type="number"
                        value={newIncomeAmount}
                        onChange={(e) => setNewIncomeAmount(e.target.value)}
                        placeholder="Cantidad"
                      />
                      <button className="add-income-button" onClick={addIncome}>
                        <FaPlus /> Añadir
                      </button>
                      <button onClick={toggleNewIncomeForm}>Cancelar</button>
                    </>
                  ) : (
                    <button
                      className="add-income-button"
                      onClick={toggleNewIncomeForm}
                    >
                      <FaPlus /> Añadir ingresos
                    </button>
                  )}
                </div>
              </div>
            </div>
            {confirmationMessages.incomes && (
              <div className="confirmation-message">
                {confirmationMessages.incomes}
              </div>
            )}
          </div>
          <div className="bento-item expenses-section bento-border">
            <div className="expenses-header">
              <h2>Gastos mensuales</h2>
              <div className="expenses-actions">
                <FaChartLine
                  onClick={() => setShowExpenseSummary(!showExpenseSummary)}
                  title="Ver resumen"
                />
                <FaFilter
                  onClick={() => setShowCategoryFilter(!showCategoryFilter)}
                  title="Filtrar por categoría"
                />
              </div>
              {showCategoryFilter && (
                <div className="category-filter-popup">
                  <div className="category-filter">
                    <button
                      className={`category-button ${
                        selectedCategory === "all" ? "active" : ""
                      }`}
                      onClick={() => handleCategorySelect("all")}
                    >
                      Todas las categorías
                    </button>
                    {categories.map((category) => (
                      <button
                        key={category}
                        className={`category-button ${
                          selectedCategory === category ? "active" : ""
                        }`}
                        onClick={() => handleCategorySelect(category)}
                      >
                        {category}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {showExpenseSummary && <ExpenseSummary expenses={expenses} />}

            <div className="expenses-list">
              {filteredExpenses.map((expense, index) => (
                <div key={index} className="expense-item">
                  {editingExpenseIndex === index ? (
                    <div className="expense-edit-form">
                      <input
                        type="text"
                        value={expense.name}
                        onChange={(e) =>
                          handleExpenseUpdate(index, "name", e.target.value)
                        }
                        placeholder="Nombre del gasto"
                      />
                      <input
                        type="number"
                        value={expense.amount}
                        onChange={(e) =>
                          handleExpenseUpdate(index, "amount", e.target.value)
                        }
                        placeholder="Cantidad"
                      />
                      <select
                        value={expense.category}
                        onChange={(e) =>
                          handleExpenseUpdate(index, "category", e.target.value)
                        }
                      >
                        {categories.map((cat) => (
                          <option key={cat} value={cat}>
                            {cat}
                          </option>
                        ))}
                      </select>
                      <div className="edit-actions">
                        <button onClick={() => handleExpenseSave(index)}>
                          Guardar
                        </button>
                        <button onClick={() => setEditingExpenseIndex(null)}>
                          Cancelar
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="expense-info">
                        <span className="expense-name">{expense.name}</span>
                        <span className="expense-category-tag">
                          {expense.category}
                        </span>
                        <span className="expense-amount">
                          {expense.amount}€
                        </span>
                      </div>
                      <div className="expense-actions">
                        <FaPencilAlt onClick={() => handleExpenseEdit(index)} />
                        <FaTrash onClick={() => handleExpenseDelete(index)} />
                      </div>
                    </>
                  )}
                </div>
              ))}

              {/* Formulario para nuevo gasto */}
              {showNewExpenseForm && (
                <div className="new-expense-form">
                  <input
                    type="text"
                    value={newExpenseName}
                    onChange={(e) => setNewExpenseName(e.target.value)}
                    placeholder="Nombre del gasto"
                  />
                  <input
                    type="number"
                    value={newExpenseAmount}
                    onChange={(e) => setNewExpenseAmount(e.target.value)}
                    placeholder="Cantidad"
                  />
                  <div className="form-actions">
                    <button
                      className="add-expense-button"
                      onClick={addExpense}
                      disabled={isSaving}
                    >
                      {isSaving ? "Añadiendo..." : "Añadir"}
                    </button>
                    <button onClick={toggleNewExpenseForm}>Cancelar</button>
                  </div>
                </div>
              )}

              {!showNewExpenseForm && (
                <button
                  className="add-expense-button"
                  onClick={toggleNewExpenseForm}
                >
                  <FaPlus /> Añadir gasto
                </button>
              )}
            </div>
          </div>
          <div className="bento-item investments-section bento-border">
            <h2>Inversiones mensuales</h2>
            <div className="investment-content">
              <div className="investment-chart">
                {investments && investments.length > 0 ? (
                  <>
                    <Pie
                      data={prepareChartData(investments)}
                      options={chartOptions}
                    />
                    <p className="investment-total">
                      Total invertido:{" "}
                      <strong>
                        {investments.reduce(
                          (acc, investment) => acc + investment.amount,
                          0
                        )}
                        €
                      </strong>
                    </p>
                  </>
                ) : (
                  <p>No hay inversiones registradas</p>
                )}
              </div>

              <div className="investment-list">
                {investments && investments.length > 0 ? (
                  investments.map((investment, index) => (
                    <div key={index} className="investment-item">
                      {editingInvestmentIndex === index ? (
                        <>
                          <input
                            type="text"
                            value={investment.name}
                            onChange={(e) =>
                              handleInvestmentUpdate(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                          <input
                            type="number"
                            value={investment.amount}
                            onChange={(e) =>
                              handleInvestmentUpdate(
                                index,
                                "amount",
                                e.target.value
                              )
                            }
                          />
                          <button onClick={() => handleInvestmentSave(index)}>
                            Guardar
                          </button>
                        </>
                      ) : (
                        <>
                          <span>
                            {investment.name}: {investment.amount}€
                          </span>
                          <div className="investment-actions">
                            <FaPencilAlt
                              onClick={() => handleInvestmentEdit(index)}
                            />
                            <FaTrash
                              onClick={() => handleInvestmentDelete(index)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No hay inversiones para mostrar</p>
                )}
                <div className="add-investment">
                  {showNewInvestmentForm ? (
                    <>
                      <input
                        type="text"
                        value={newInvestmentName}
                        onChange={(e) => setNewInvestmentName(e.target.value)}
                        placeholder="Nombre de la inversión"
                      />
                      <input
                        type="number"
                        value={newInvestmentAmount}
                        onChange={(e) => setNewInvestmentAmount(e.target.value)}
                        placeholder="Cantidad"
                      />
                      <button
                        className="add-investment-button"
                        onClick={addInvestment}
                      >
                        <FaPlus /> Añadir
                      </button>
                      <button onClick={toggleNewInvestmentForm}>
                        Cancelar
                      </button>
                    </>
                  ) : (
                    <button
                      className="add-investment-button"
                      onClick={toggleNewInvestmentForm}
                    >
                      <FaPlus /> Añadir inversión
                    </button>
                  )}
                </div>
              </div>
            </div>
            {confirmationMessages.investments && (
              <div className="confirmation-message">
                {confirmationMessages.investments}
              </div>
            )}
          </div>
          <div className="bento-item calculator-section bento-border">
            <h2>Calculadora de Interés Compuesto</h2>
            <div className="calculator-content">
              <div className="calculator-inputs">
                <div className="input-group">
                  <label>Capital inicial:</label>
                  <div className="input-wrapper">
                    <div className="input-currency-wrapper">
                      <input
                        type="number"
                        value={capitalInicial}
                        onChange={(e) => setCapitalInicial(e.target.value)}
                        className="form-control"
                      />
                      <span className="currency-symbol">€</span>
                    </div>
                    <div className="input-buttons">
                      <button
                        onClick={handleIncrement(
                          setCapitalInicial,
                          capitalInicial,
                          1000
                        )}
                      >
                        +
                      </button>
                      <button
                        onClick={handleDecrement(
                          setCapitalInicial,
                          capitalInicial,
                          1000
                        )}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Aportación mensual:</label>
                  <div className="input-wrapper">
                    <div className="input-currency-wrapper">
                      <input
                        type="number"
                        value={aportacionMensual}
                        onChange={(e) => setAportacionMensual(e.target.value)}
                        className="form-control"
                      />
                      <span className="currency-symbol">€</span>
                    </div>
                    <div className="input-buttons">
                      <button
                        onClick={handleIncrement(
                          setAportacionMensual,
                          aportacionMensual,
                          100
                        )}
                      >
                        +
                      </button>
                      <button
                        onClick={handleDecrement(
                          setAportacionMensual,
                          aportacionMensual,
                          100
                        )}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Años:</label>
                  <div className="input-wrapper-no-currency">
                    <div className="input-no-currency-wrapper">
                      <input
                        type="number"
                        value={anos}
                        onChange={(e) => setAnos(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="input-buttons">
                      <button onClick={handleIncrement(setAnos, anos, 1)}>
                        +
                      </button>
                      <button onClick={handleDecrement(setAnos, anos, 1)}>
                        -
                      </button>
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Tasa de interés (%):</label>
                  <div className="input-wrapper-no-currency">
                    <div className="input-no-currency-wrapper">
                      <input
                        type="number"
                        value={tasaInteres}
                        onChange={(e) => setTasaInteres(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="input-buttons">
                      <button
                        onClick={handleIncrement(
                          setTasaInteres,
                          tasaInteres,
                          1
                        )}
                      >
                        +
                      </button>
                      <button
                        onClick={handleDecrement(
                          setTasaInteres,
                          tasaInteres,
                          1
                        )}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={considerarInflacion}
                      onChange={(e) => setConsiderarInflacion(e.target.checked)}
                    />
                    Considerar inflación
                  </label>
                </div>

                {considerarInflacion && (
                  <div className="input-group inflation-input">
                    <label>Tasa de inflación (%):</label>
                    <input
                      type="number"
                      value={tasaInflacion}
                      onChange={(e) => setTasaInflacion(e.target.value)}
                      className="form-control"
                    />
                  </div>
                )}
              </div>

              <div className="calculator-results">
                {resultado && (
                  <>
                    <h3>Resumen de la inversión</h3>
                    <p>
                      Valor final {considerarInflacion ? "real" : "nominal"}:{" "}
                      {resultado}€
                    </p>
                    <p>
                      Ganancias totales{" "}
                      {considerarInflacion ? "reales" : "nominales"}: {ganado}€
                    </p>
                    <p>Plazo: {anos} años</p>
                    {considerarInflacion && (
                      <p className="inflation-note">
                        * Los valores están ajustados por una inflación del{" "}
                        {tasaInflacion}% anual
                      </p>
                    )}
                  </>
                )}
              </div>

              <div className="calculator-chart">
                {dataChart.nominal && (
                  <Bar
                    data={{
                      labels: Array.from({ length: anos }, (_, i) => i + 1),
                      datasets: [
                        {
                          label: "Balance nominal",
                          data: dataChart.nominal,
                          backgroundColor: "rgba(75, 192, 192, 1)",
                          borderColor: getChartColors().grid,
                          borderWidth: 1,
                        },
                        ...(dataChart.real
                          ? [
                              {
                                label: "Balance real (ajustado por inflación)",
                                data: dataChart.real,
                                backgroundColor: "rgba(255, 99, 132, 0.8)",
                                borderColor: getChartColors().grid,
                                borderWidth: 1,
                              },
                            ]
                          : []),
                      ],
                    }}
                    options={calculatorChartOptions}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="bento-item chatbot-section bento-border">
            <h2>Asistente Virtual</h2>
            <Chatbot />
          </div>
          <div className="bento-item actions">
            <button className="logout-button" onClick={handleLogout}>
              Cerrar Sesión
            </button>
            <button
              className="delete-account-button"
              onClick={() => setShowDeleteConfirm(true)}
            >
              Eliminar Cuenta
            </button>

            {showDeleteConfirm && (
              <div className="delete-confirm-modal">
                <div className="modal-content">
                  <h3>¿Estás seguro?</h3>
                  <p>Esta acción no se puede deshacer.</p>
                  <div className="modal-buttons">
                    <button onClick={handleDeleteAccount}>Sí, eliminar</button>
                    <button onClick={() => setShowDeleteConfirm(false)}>
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {error && <div className="error-message">{error}</div>}
      {isSaving && <div className="saving-indicator">Guardando cambios...</div>}
    </div>
  );
}

export default Dashboard;
