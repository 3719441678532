import React from "react";
import { Link } from "react-router-dom";
import { FaChartLine, FaRobot, FaShieldAlt } from "react-icons/fa";
import "./Homepage.css";

const Homepage = () => {
  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Controla tus finanzas con el poder de la IA</h1>
          <p className="hero-subtitle">
            Optimiza tus gastos, alcanza tus metas y toma mejores decisiones
            financieras con nuestra plataforma impulsada por inteligencia
            artificial
          </p>
          <div className="cta-buttons">
            <Link to="/register" className="btn btn-primary">
              Comenzar Gratis
            </Link>
            <Link to="/demo" className="btn btn-secondary">
              Ver Demo
            </Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2>Características Principales</h2>
        <div className="features-grid">
          <div className="feature-card">
            <FaChartLine className="feature-icon" />
            <h3>Análisis Inteligente</h3>
            <p>
              Obtén insights personalizados sobre tus patrones de gasto y
              oportunidades de ahorro
            </p>
          </div>
          <div className="feature-card">
            <FaRobot className="feature-icon" />
            <h3>Asistente IA</h3>
            <p>
              Recibe recomendaciones personalizadas y respuestas a tus dudas
              financieras
            </p>
          </div>
          <div className="feature-card">
            <FaShieldAlt className="feature-icon" />
            <h3>Seguridad Garantizada</h3>
            <p>
              Tus datos financieros están protegidos con la más alta tecnología
              de encriptación
            </p>
          </div>
        </div>
      </section>

      {/* How it Works Section */}
      <section className="how-it-works">
        <h2>¿Cómo Funciona?</h2>
        <div className="steps-container">
          <div className="step">
            <div className="step-number">1</div>
            <h3>Conecta tus Cuentas</h3>
            <p>
              Integra fácilmente tus cuentas bancarias y fuentes de ingresos
            </p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>Análisis Automático</h3>
            <p>Nuestra IA analiza tus transacciones y patrones financieros</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>Recibe Insights</h3>
            <p>
              Obtén recomendaciones personalizadas para mejorar tus finanzas
            </p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <div className="cta-content">
          <h2>Comienza tu Viaje Financiero Hoy</h2>
          <p>
            Únete a miles de usuarios que ya están mejorando sus finanzas con IA
          </p>
          <Link to="/register" className="btn btn-primary">
            Crear Cuenta Gratuita
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Homepage;
