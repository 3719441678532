import React from "react";
import "./legalnotice.css";

function LegalNotice() {
  return (
    <div className="legal-notice-container">
      <h1>Aviso Legal</h1>

      <section className="legal-section">
        <h2>1. Datos Identificativos</h2>
        <p>
          En cumplimiento con el deber de información recogido en artículo 10 de
          la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y del Comercio Electrónico, a continuación se reflejan los
          siguientes datos:
        </p>
        <ul>
          <li>Denominación: MyFinanceBrain</li>
          <li>Contacto: hello@myfinancebrain.com</li>
          <li>Actividad: Aplicación web de gestión financiera personal</li>
        </ul>
      </section>

      <section className="legal-section">
        <h2>2. Política de Privacidad</h2>
        <p>
          De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 del
          Parlamento Europeo y del Consejo, de 27 de abril de 2016 (RGPD), le
          informamos que:
        </p>
        <ul>
          <li>
            Los datos personales proporcionados serán tratados con la finalidad
            de gestionar su cuenta y proporcionar los servicios de la
            aplicación.
          </li>
          <li>
            La base legal para el tratamiento de sus datos es la ejecución del
            contrato de servicios y su consentimiento.
          </li>
          <li>
            Los datos se conservarán mientras se mantenga la relación de
            servicio y no se solicite su supresión.
          </li>
          <li>No se cederán datos a terceros salvo obligación legal.</li>
          <li>
            Puede ejercer sus derechos de acceso, rectificación, supresión,
            portabilidad, limitación y oposición contactando a través del email
            [tu email].
          </li>
        </ul>
      </section>

      <section className="legal-section">
        <h2>3. Política de Cookies</h2>
        <p>
          Esta aplicación utiliza cookies técnicas esenciales para el
          funcionamiento del servicio:
        </p>
        <ul>
          <li>
            Cookies de sesión para mantener el inicio de sesión del usuario
          </li>
          <li>
            Cookies técnicas necesarias para el funcionamiento básico de la
            aplicación
          </li>
        </ul>
        <p>
          Al utilizar nuestra aplicación, acepta el uso de estas cookies
          esenciales para su funcionamiento.
        </p>
      </section>

      <section className="legal-section">
        <h2>4. Condiciones de Uso</h2>
        <ul>
          <li>
            El usuario se compromete a hacer un uso adecuado de los contenidos y
            servicios de la aplicación.
          </li>
          <li>
            No está permitido realizar actividades ilícitas o contrarias a la
            buena fe.
          </li>
          <li>
            El usuario es responsable de la confidencialidad de sus credenciales
            de acceso.
          </li>
          <li>
            Nos reservamos el derecho a modificar o suspender el servicio sin
            previo aviso.
          </li>
        </ul>
      </section>

      <section className="legal-section">
        <h2>5. Propiedad Intelectual e Industrial</h2>
        <p>
          Todos los derechos de propiedad intelectual e industrial de esta
          aplicación, incluyendo su código fuente, diseño, estructuras de
          navegación y textos están protegidos por la legislación vigente.
        </p>
      </section>

      <section className="legal-section">
        <h2>6. Responsabilidad</h2>
        <p>MyFinanceBrain no se hace responsable de:</p>
        <ul>
          <li>
            Las decisiones financieras tomadas por los usuarios basadas en la
            información proporcionada.
          </li>
          <li>La exactitud de los datos introducidos por los usuarios.</li>
          <li>
            Interrupciones temporales del servicio por mantenimiento o causas
            técnicas.
          </li>
          <li>
            Daños y perjuicios de cualquier naturaleza por el uso de la
            aplicación.
          </li>
        </ul>
      </section>

      <section className="legal-section">
        <h2>7. Modificaciones</h2>
        <p>
          Nos reservamos el derecho de modificar este Aviso Legal en cualquier
          momento. Los cambios entrarán en vigor desde su publicación.
        </p>
        <p>
          Última actualización:{" "}
          {new Date().toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
      </section>

      <section className="legal-section">
        <h2>8. Descargo de Responsabilidad sobre Inversiones</h2>
        <p>
          La información y funcionalidades proporcionadas en MyFinanceBrain
          tienen un propósito meramente informativo y educativo. En ningún caso
          constituyen:
        </p>
        <ul>
          <li>Asesoramiento financiero profesional</li>
          <li>Recomendaciones de inversión</li>
          <li>Consejos personalizados sobre productos financieros</li>
        </ul>
        <p>
          Todas las decisiones de inversión o financieras que tome el usuario
          son de su exclusiva responsabilidad. Recomendamos siempre consultar
          con un asesor financiero profesional antes de tomar cualquier decisión
          de inversión. MyFinanceBrain no se hace responsable de las pérdidas o
          ganancias que puedan resultar del uso de la información proporcionada
          en la aplicación.
        </p>
      </section>
    </div>
  );
}

export default LegalNotice;
