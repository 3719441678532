import React from "react";
import "./CalculadoraFaqs.css";

const CalculadoraFaqs = () => {
  const faqData = [
    {
      title: "¿Qué es el interés compuesto?",
      content: (
        <>
          <p>
            El interés compuesto es un concepto financiero que se refiere al
            proceso de ganar intereses sobre el capital inicial invertido y
            sobre los intereses acumulados de periodos anteriores. Esto
            significa que, con el tiempo, el dinero que inviertes no solo genera
            rendimientos, sino que esos rendimientos también empiezan a generar
            más ganancias.
          </p>
          <div className="einstein-quote">
            <div className="quote-text">
              <p>"El interés compuesto es la octava maravilla del mundo."</p>
              <span>Albert Einstein</span>
            </div>
            <div className="quote-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/interes-compuesto-einstein.jpg`}
                alt="Albert Einstein y su famosa frase sobre el interés compuesto"
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Beneficios del interés compuesto",
      content: (
        <>
          <p>
            El interés compuesto ofrece varios beneficios clave para quienes
            buscan hacer crecer su dinero de manera eficiente a largo plazo:
          </p>
          <ul>
            <li>
              <strong>Crecimiento exponencial</strong>: Tus ganancias no solo se
              basan en el capital inicial, sino que también los intereses
              generados comienzan a generar más intereses, creando un efecto
              multiplicador.
            </li>
            <li>
              <strong>Mayor rentabilidad a largo plazo</strong>: Cuanto más
              tiempo dejas que el interés compuesto trabaje, más rápido crece tu
              inversión, lo que lo convierte en una estrategia ideal para
              inversiones a largo plazo.
            </li>
            <li>
              <strong>Fomenta el ahorro</strong>: El interés compuesto incentiva
              a ahorrar e invertir temprano, ya que cuanto antes empieces,
              mayores serán los beneficios.
            </li>
            <li>
              <strong>Adaptable a diferentes inversiones</strong>: El interés
              compuesto puede aplicarse a cuentas de ahorro, bonos, acciones, y
              fondos de inversión, lo que lo hace versátil para todo tipo de
              perfiles inversores.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "¿Cómo funciona la calculadora de interés compuesto?",
      content: (
        <>
          <p>
            La calculadora de interés compuesto es una herramienta fácil de usar
            que te ayuda a visualizar cómo crecerá tu dinero con el tiempo.
            Funciona de la siguiente manera:
          </p>
          <ol>
            <li>Introduces tu inversión inicial (por ejemplo, 1000€).</li>
            <li>Añades cuánto planeas aportar cada mes (digamos, 100€).</li>
            <li>
              Eliges por cuántos años quieres invertir (por ejemplo, 10 años).
            </li>
            <li>
              Indicas el porcentaje de rendimiento anual que esperas (como un
              7%).
            </li>
          </ol>
          <p>
            La calculadora entonces hace la magia: multiplica, suma y calcula
            año tras año cómo crecerá tu dinero. Te mostrará el resultado final
            y un gráfico para que puedas ver el crecimiento.
          </p>
          <p>
            Por ejemplo, con los datos anteriores, podrías ver que tu inversión
            inicial de 1000€, con aportes mensuales de 100€ durante 10 años y un
            rendimiento del 7% anual, podría convertirse en más de 20,000€. ¡Es
            como ver crecer una planta, pero con tu dinero!
          </p>
        </>
      ),
    },
    {
      title: "Preguntas Frecuentes sobre el Interés Compuesto",
      content: (
        <div className="faq-section">
          {[
            {
              question: "¿Qué es el interés compuesto?",
              answer:
                "El interés compuesto es el proceso de ganar intereses sobre el capital inicial y sobre los intereses acumulados de periodos anteriores, lo que permite que el dinero crezca exponencialmente con el tiempo.",
            },
            {
              question: "¿Cómo funciona la calculadora de interés compuesto?",
              answer:
                "La calculadora de interés compuesto toma en cuenta el capital inicial, la tasa de interés, el periodo de tiempo y la frecuencia de capitalización para calcular cómo crecerá tu inversión a lo largo del tiempo.",
            },
            {
              question:
                "¿Cuál es la diferencia entre interés simple e interés compuesto?",
              answer:
                "La diferencia principal es que el interés simple solo genera rendimientos sobre el capital inicial, mientras que el interés compuesto genera rendimientos tanto sobre el capital inicial como sobre los intereses acumulados.",
            },
            {
              question: "¿Por qué es importante empezar a invertir temprano?",
              answer:
                "Invertir temprano permite que el interés compuesto tenga más tiempo para trabajar, lo que maximiza el crecimiento exponencial de tus inversiones a largo plazo.",
            },
          ].map((faq, index) => (
            <div key={index} className="faq-item">
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      ),
    },
  ];

  const jsonLdFaqs = [
    {
      question: "¿Qué es el interés compuesto?",
      answer:
        "El interés compuesto es el proceso de ganar intereses sobre el capital inicial y sobre los intereses acumulados de periodos anteriores, lo que permite que el dinero crezca exponencialmente con el tiempo.",
    },
    {
      question: "¿Cómo funciona la calculadora de interés compuesto?",
      answer:
        "La calculadora de interés compuesto toma en cuenta el capital inicial, la tasa de interés, el periodo de tiempo y la frecuencia de capitalización para calcular cómo crecerá tu inversión a lo largo del tiempo.",
    },
    {
      question:
        "¿Cuál es la diferencia entre interés simple e interés compuesto?",
      answer:
        "La diferencia principal es que el interés simple solo genera rendimientos sobre el capital inicial, mientras que el interés compuesto genera rendimientos tanto sobre el capital inicial como sobre los intereses acumulados.",
    },
    {
      question: "¿Por qué es importante empezar a invertir temprano?",
      answer:
        "Invertir temprano permite que el interés compuesto tenga más tiempo para trabajar, lo que maximiza el crecimiento exponencial de tus inversiones a largo plazo.",
    },
  ];

  return (
    <>
      <div className="calculadora-faqs-grid">
        {faqData.map((item, index) => (
          <div key={index} className="calculadora-faq-item bento-border">
            <h2>{item.title}</h2>
            {item.content}
          </div>
        ))}
      </div>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: jsonLdFaqs.map((faq) => ({
            "@type": "Question",
            name: faq.question,
            acceptedAnswer: {
              "@type": "Answer",
              text: faq.answer,
            },
          })),
        })}
      </script>
    </>
  );
};

export default CalculadoraFaqs;
